<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="emoticonRowContainer">
          <div
            v-for="(item, index) in getFilteredEmotionsByLimit()"
            :key="index"
            class="emoticonGroup"
          >
            <div
              class="emoticonCategory"
              :style="`background-color: #${item.color}`"
            />
            <p class="emoticonText">{{ item.text }}</p>
          </div>
        </div>

        <div class="filterBtnRowContainer">
          <div
            class="filterBtn bg-blue btn shadow"
            @click="getEmotionsByFilter('daily')"
          >
            {{ $t("labels.perDay") }}
          </div>
          <div
            class="filterBtn bg-pink btn shadow"
            @click="getEmotionsByFilter('weekly')"
          >
            {{ $t("labels.perWeek") }}
          </div>
          <div
            class="filterBtn bg-yellow btn shadow"
            @click="getEmotionsByFilter('monthly')"
          >
            {{ $t("labels.perMonth") }}
          </div>
        </div>

        <div class="calcContainer">
          <div class="sortColumn">
            <div
              v-for="(item, index) in getFilteredEmotionsByLimit()"
              :key="index"
              class="sortItem"
            >
              <div
                class="sort"
                :style="`background-color: #${item.color}; color: ${invertColor(
                  item.color,
                  true
                )}`"
              >
                {{ item.text }}
              </div>
              <div
                class="sortTale"
                :style="`border-top-color: #${item.color}`"
              />
            </div>
            <div v-if="emotions[filter].length > 5" class="sortItem">
              <div
                class="sort"
                :style="`background-color: #eeeeee; color: ${invertColor(
                  'eeeeee',
                  true
                )}`"
              >
                {{ $t("labels.rest") }}
              </div>
              <div class="sortTale" :style="`border-top-color: #eeeeee`" />
            </div>
          </div>
          <div class="graphColumn">
            <div
              v-for="(item, index) in getFilteredEmotionsByLimit()"
              :key="index"
              class="graphBarContainer"
            >
              <div
                class="graphBar"
                :style="`width: ${getPercentageOfEmotion(
                  item.count
                )}%; background-color: #${item.color};`"
              />
              <p class="percent">
                {{ `${getPercentageOfEmotion(item.count)}%` }}
              </p>
            </div>
            <div v-if="emotions[filter].length > 5" class="graphBarContainer">
              <div
                class="graphBar"
                :style="`width: ${getPercentageOfTotalRest()}%; background-color: #eeeeee;`"
              />
              <p class="percent">
                {{ `${getPercentageOfTotalRest()}%` }}
              </p>
            </div>
          </div>
        </div>
        <div class="subContainer noteContainer mt-50">...</div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import CustomHeader from "../components/CustomHeader.vue";
export default {
  name: "CalcPage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      topicImage: require("../assets/image/png/people.png"),
      filter: 'daily'
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getFilteredEmotions() {
      if (this.emoticons.length == 0 || this.emotions[this.filter].length == 0)
        return [];

      let emotions_DetailData = [];
      for (let i = 0; i < this.emotions[this.filter].length; i++) {
        let item = this.emotions[this.filter][i];
        emotions_DetailData.push({
          ...this.emoticons.find((x) => x.id === item.emotion_id),
          count: item.count,
        });
      }
      emotions_DetailData.sort((a, b) =>
        a.count < b.count ? 1 : b.count < a.count ? -1 : 0
      );
      return emotions_DetailData;
    },
    getFilteredEmotionsByLimit() {
      let emotions_DetailData = this.getFilteredEmotions();
      if (emotions_DetailData.length <= 5) {
        return emotions_DetailData;
      } else {
        let array = [];
        for (let i = 0; i < 5; i++) {
          array.push(emotions_DetailData[i]);
        }
        return array;
      }
    },
    padZero(str, len) {
      len = len || 2;
      var zeros = new Array(len).join("0");
      return (zeros + str).slice(-len);
    },
    invertColor(hex, bw) {
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    getPercentageOfEmotion(count) {
      let filteredEmotions = this.getFilteredEmotions();
      let total = 0;
      for (let i = 0; i < filteredEmotions.length; i++) {
        let item = filteredEmotions[i];
        total += parseInt(item.count);
      }
      return ((parseInt(count) / total) * 100).toFixed(2);
    },
    getPercentageOfTotalRest() {
      let filteredEmotions = this.getFilteredEmotions();
      let total = 0;
      let restTotal = 0;
      for (let i = 0; i < filteredEmotions.length; i++) {
        let item = filteredEmotions[i];
        total += parseInt(item.count);
        if (i > 4) {
          restTotal += parseInt(item.count);
        }
      }
      return ((parseInt(restTotal) / total) * 100).toFixed(2);
    },
    getEmotionsByFilter(filter) {
      this.filter = filter;
      this.$store.dispatch("main/getEmotionsByFilterAttempt", filter);
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },

  computed: mapState({
    emoticons: (state) => state.main.emoticons,
    emotions: (state) => state.main.emotions,
  }),
  mounted() {
    this.$store.dispatch("main/getEmoticons");
    this.getEmotionsByFilter("daily");
  },
};
</script>

<style scoped>
.emoticonRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 10px;
  height: 100px;
}
.emoticonGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emoticonCategory {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid black;
}
.emoticonText {
  font-size: 12px;
  margin-top: 10px;
  color: var(--textColor);
}
@media only screen and (max-width: 400px) {
  .emoticonCategory {
    width: 40px;
    height: 40px;
  }
  .emoticonText {
    font-size: 10px;
    margin-top: 8px;
  }
  .emoticonRowContainer {
    height: 80px;
  }
}
.filterBtnRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.filterBtn {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px 0px;
  font-size: 13px;
  color: white;
}
@media only screen and (max-width: 400px) {
  .filterBtn {
    font-size: 10px;
  }
}
.calcContainer {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.sortColumn {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-right: 1px solid lightgray;
  padding-right: 10px;
}
.sortItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.sort {
  width: 100%;
  height: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}
.sortTale {
  width: 0px;
  height: 0px;
  display: flex;
  background-color: transparent;
  border-style: solid;
  border-right-width: 15px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-top-width: 15px;
  border-right-color: transparent;
  align-self: flex-end;
  transform: rotate(90deg);
  margin-top: -1px;
}

.graphColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
}
.graphBarContainer {
  width: 100%;
  overflow: visible;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
}
.percent {
  font-size: 12px;
  color: black;
  margin-left: 3px;
}
@media only screen and (max-width: 400px) {
  .percent {
    font-size: 10px;
  }
}
.graphBar {
  height: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.noteContainer {
  padding: 10px;
  font-size: 18px;
  color: var(--textColor);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>